import Vue from "vue";
import Vuex from "vuex";
import tab from "./tab";
import user from "./user";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        tab,
        user
    },

    state: {
        permissionList: []
    },

    mutations: {
        setPermission(state, permissionList) {
            state.permissionList = permissionList;
            localStorage.setItem("CONFIG_PERMISSION", JSON.stringify(permissionList));
        }
    }
})
