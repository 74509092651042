import Vue from "vue";
import App from "./App.vue";
import {
    Aside,
    Badge,
    Breadcrumb,
    BreadcrumbItem,
    Button,
    Card,
    Cascader,
    Checkbox,
    Col,
    Container,
    DatePicker,
    Descriptions,
    DescriptionsItem,
    Dialog,
    Divider,
    Drawer,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    Form,
    FormItem,
    Header,
    Image,
    Input,
    Loading,
    Main,
    Menu,
    MenuItem,
    MenuItemGroup,
    Message,
    MessageBox,
    Option,
    Pagination,
    Popover,
    Radio,
    RadioGroup,
    Row,
    Scrollbar,
    Select,
    Submenu,
    Switch,
    Table,
    TableColumn,
    TabPane,
    Tabs,
    Tag,
    TimePicker,
    Transfer,
    Tree,
    Upload
} from "element-ui"; //按需引入
//import ElementUI from 'element-ui'; //全局引入
import "element-ui/lib/theme-chalk/index.css";
import "./assets/less/index.less";

import router from "./router";
import store from "./store";
import http from "axios";
//import "./api/mock.js";
import VueAMap from 'vue-amap'
import Cookie from 'js-cookie';
import Directive from './directive';
import iconPicker from 'vue-fontawesome-elementui-icon-picker'

Vue.config.productionTip = false;
//Vue.use(ElementUI)

Vue.use(Button);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Container);
Vue.use(Main);
Vue.use(Header);
Vue.use(Aside);
Vue.use(Menu);
Vue.use(Submenu);
Vue.use(MenuItem);
Vue.use(MenuItemGroup);
Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Row);
Vue.use(Col);
Vue.use(Card);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Breadcrumb);
Vue.use(BreadcrumbItem);
Vue.use(Tag);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Input);
Vue.use(Select);
Vue.use(Switch);
Vue.use(DatePicker);
Vue.use(TimePicker);
Vue.use(Upload);
Vue.use(Option);
Vue.use(Dialog);
Vue.use(Pagination);
Vue.use(Drawer);
Vue.use(Tree);
Vue.use(Cascader);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Descriptions);
Vue.use(DescriptionsItem);
Vue.use(Divider);
Vue.use(Image);
Vue.use(Loading);
Vue.use(Transfer);
Vue.use(Popover);
Vue.use(Scrollbar);
Vue.use(Checkbox);
Vue.use(VueAMap);
Vue.use(Directive);
Vue.use(iconPicker);
Vue.use(Badge);

Vue.prototype.$http = http;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$message = Message

//导航守卫
router.beforeEach((to, from, next) => {
    // 判断Authorization是否存在
    var authorization = Cookie.get('Authorization')
    if (authorization == undefined) {
        authorization = null;
    }

    if (authorization == null && to.name != 'login' && to.name != 'confirm') {
        // authorization不存在，请求业务界面，跳转登录
        next({name: 'login'})
    } else if (authorization != null && to.name == 'login') {
        // authorization存在，请求登录页面，跳转主页
        next({name: 'home'})
    } else {
        next();
    }
})

VueAMap.initAMapApiLoader({
    key: 'dc93538750d24942cf383cbe6bfc44b1',
    plugin: ['AMap.Autocomplete',
        'AMap.PlaceSearch',
        'AMap.Scale',
        'AMap.OverView',
        'AMap.ToolBar',
        'AMap.MapType',
        'AMap.PolyEditor',
        'AMap.CircleEditor'],
    v: '1.4.4',
    uiVersion: '1.0.11'
})

Vue.prototype.$http = http;

new Vue({
    router,
    store,
    render: (h) => h(App),
    created() {
        store.commit('addMenu', router)
    }
}).$mount("#app");
