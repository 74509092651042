<template>
  <div class="container">
    
    <router-view></router-view>
  </div>
</template>

<script>
//import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
}
</script>

<style>
.container {
  padding: 0px;
  height: 100%;
}

/* 对于小屏幕设备（例如手机），将容器的宽度设定为100% */
@media only screen and (max-width: 600px) {
  .container {
    width: 100%;
  }
}
</style>
