import Cookie from "js-cookie"

export default {
    state: {
        tabsList: [
            {
                path: '/home',
                name: 'home',
                label: '首页',
                icon: 'home'
            }
        ],
        currentMenu: null,
        menu: []
    },

    mutations: {
        selectMenu(state, val) {
            if (val.name !== '') {
                state.currentMenu = val;
                const result = state.tabsList.findIndex(item => item.name === val.name);
                if (result === -1) {
                    state.tabsList.push(val);
                }
            } else {
                state.currentMenu = null;
            }
        },

        // 删除面包屑里的关闭数据
        closeTag(state, val) {
            // 当前点击的数据
            const result = state.tabsList.findIndex(item => item.name === val.name);
            state.tabsList.splice(result, 1);
        },

        // 清空面包屑数据
        clearTag(state) {
            // 清空
            state.tabsList = [];
        },

        // 设置menu数据
        setMenu(state, val) {
            state.menu = val;
            // Cookie.set('menu', JSON.stringify(val));
            localStorage.setItem('menu', JSON.stringify(val));
        },

        clearMenu(state) {
            state.menu = [];
            Cookie.remove('menu');
        },

        // 动态注册路由
        addMenu(state, router) {
            // 判断cookie中是否有数据
            // if(!Cookie.get('menu')){
            //     return
            // }
            if (!localStorage.getItem('menu')) {
                return;
            }

            //const menu = JSON.parse(Cookie.get('menu'));
            const menu = JSON.parse(localStorage.getItem('menu'));
            state.menu = menu;

            // 组装动态路由的数据
            const menuArray = [];
            menu.forEach(item => {
                if (item.children) {
                    item.children = item.children.map(item => {
                        item.component = () => import(`../views/${item.url}`);
                        return item;
                    });

                    menuArray.push(...item.children);
                } else {
                    item.component = () => import(`../views/${item.url}`);
                    menuArray.push(item);
                }
            });

            // 添加动态路由
            menuArray.forEach(item => {
                router.addRoute('Main', item);
            });
        }
    }
}