import Vue from 'vue'
import VueRouter from 'vue-router'
//import { component } from 'vue/types/umd'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

const routes = [
    {
        path: '/',
        name: 'Main',
        component: () => import('../views/Main.vue'),
        children: [
            // {
            // 		path: '/home',
            // 		name: 'home',
            // 		component: () => import('../views/home')
            // },
            // {
            // 		path: '/orgchart',
            // 		name: 'orgchart',
            // 		component: () => import('../views/orgchart')
            // },
            // {
            // 		path: '/party',
            // 		name: 'party',
            // 		component: () => import('../views/party')
            // },
            // {
            // 		path: '/case',
            // 		name: 'case',
            // 		component: () => import('../views/case')
            // },
            // {
            // 		path: '/thrmt',
            // 		name: 'thrmt',
            // 		component: () => import('../views/thrmt')
            // },
            // {
            // 		path: '/mat',
            // 		name: 'mat',
            // 		component: () => import('../views/mat')
            // },
            // {
            // 		path: '/product',
            // 		name: 'product',
            // 		component: () => import('../views/product')
            // },
            // {
            // 		path: '/pkg',
            // 		name: 'pkg',
            // 		component: () => import('../views/pkg')
            // },
            // {
            // 		path: '/order',
            // 		name: 'order',
            // 		component: () => import('../views/order')
            // },
        ]
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/login/login.vue')
    },
    {
        path: '/confirm',
        name: 'confirm',
        component: () => import('../views/confirm/index.vue'),
        hidden: true
    },
]

const router = new VueRouter({
    mode: 'history',
    routes
})

export default router