import Cookie from "js-cookie"

export default {
    state: {
        token: '',
        username: '',
        realName: '',
        mobile: '',
        party: ''
    },

    mutations: {
        setToken(state, val) {
            state.token = val;
            Cookie.set('Authorization', val);
        },

        setUsername(state, val) {
            state.username = val;
            Cookie.set('username', val);
        },

        setRealName(state, val) {
            state.realName = val;
            Cookie.set('realName', val);
        },

        setMobile(state, val) {
            state.mobile = val;
            Cookie.set('mobile', val);
        },

        setParty(state, val) {
            state.party = val;
            Cookie.set('party', val);
        },

        clearToken(state) {
            state.token = '';
            state.username = '';
            state.realName = '';
            state.mobile = '';
            state.party = '';
            Cookie.remove('Authorization');
            Cookie.remove('username');
            Cookie.remove('realName');
            Cookie.remove('mobile');
            Cookie.remove('party');
        },

        getToken(state) {
            state.token = state.token || Cookie.get('Authorization');
        },

        getUsername(state) {
            state.username = state.username || Cookie.get('username');
        },

        getRealName(state) {
            state.realName = state.realName || Cookie.get('realName');
        },

        getMobile(state) {
            state.mobile = state.mobile || Cookie.get('mobile');
        },

        getParty(state) {
            state.party = state.party || Cookie.get('party');
        }
    }
}